import { defineComponent as _defineComponent } from 'vue'
import PerformanceOverviewForm from "@ems/containers/Dashboard/TheForm/PerformanceOverview.Form.vue";
import Pie3dChart from "@/components/AnyChart/Pie3dChart.vue";

import {
  piedEnergy,
  piedRenewables,
} from "../../../data/PieChart_PerformanceOverview";

export default /*@__PURE__*/_defineComponent({
  __name: 'PO',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { PerformanceOverviewForm, Pie3dChart, get piedEnergy() { return piedEnergy }, get piedRenewables() { return piedRenewables } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})