import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, reactive, ref } from "vue";

interface Props {
  data: any[];
  title?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Pie3dChart',
  props: {
    data: {},
    title: {}
  },
  setup(__props: any) {

const props = __props;
const { data, title } = reactive(props);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const anychart = require("anychart/dist/js/anychart-pie.min.js");

const el = ref(null);
let chart = reactive<any>(null);

onMounted(() => {
  // create a 3d pie chart and set the data
  chart = anychart.pie3d(data);
  chart.background(null);
  // set the chart title
  chart.legend({ position: "bottom", fontSize: 12, padding: 0 });
  chart.legend().itemsLayout("vertical");

  // set the position of labels
  chart.labels({ fontColor: "#fff", fontSize: 12, position: "outside" });

  // configure connectors
  chart.connectorStroke({ color: "#fff", thickness: 1.5, lineCap: "round" });

  // Set critical angle.
  chart.outsideLabelsCriticalAngle(160);

  // adjust chart settings
  const chartTitle = chart.legend().title();
  // enable title
  chartTitle.enabled(true);
  // set title text
  chartTitle.text(title);
  chartTitle.fontColor("#fff");
  // place title into bottom of the chart
  chartTitle.orientation("top");
  chartTitle.padding([0, 0, 10, 0]);
  // stick title to the left side
  chartTitle.align("left");

  // chart.title(title).orientation("bottom");

  chart.animation(true);

  chart.container(el.value).draw();

  // if (!chart && props.options) {
  //   chart = new anychart.fromJson(props.options);
  //   chart.container(el.value).draw();
  // }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full h-96 mx-auto py-8",
    ref_key: "el",
    ref: el
  }, null, 512))
}
}

})