import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 2xl:gap-5 gap-3 mb-5" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "md:mt-5 lg:mt-0 lg:ml-10 2xl:col-span-2" }
const _hoisted_7 = { class: "flex lg:flex-col 2xl:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "performance-overview" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Performance Overview ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["PerformanceOverviewForm"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-left text-xl font-bold" }, " Performance Overview (Facility 1) ", -1)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode($setup["Pie3dChart"], {
            title: "Energy",
            data: $setup.piedEnergy
          }, null, 8, ["data"]),
          _createVNode($setup["Pie3dChart"], {
            title: "Renewables",
            data: $setup.piedRenewables
          }, null, 8, ["data"])
        ])
      ])
    ])
  ]))
}